import React, { memo, useState } from "react";
import { PopupWraper } from "../../../components/popup";
import { TableFilterInputText } from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import { ITableStructureItem, NumberUtils, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withUserWraper } from "../wraper";
import "./UserList.scss";
import { Icon } from "../../../components";

export const UserDetails = withUserWraper(
  memo(() => {
    const [sponsorUserId, setSponsorUserId] = useState<any>(null);

    const structure: ITableStructureItem[] = [
      {
        name: "EMAIL",
        key: "email",
        render: (item) => {
          return <span className="email">{item.email}</span>;
        },
      },
      {
        name: "SPONSOR",
        key: "sponsorEmail",
        render: (item) =>
          item?.sponsorEmail ? (
            <span className="d-flex aligns-items-center">
              {item.sponsorEmail}
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setSponsorUserId(item?.userId)}
              >
                <Icon.Plus />
              </span>
            </span>
          ) : (
            ""
          ),
      },
      {
        name: "TOTAL REFERRAL",
        key: "totalReferral",
      },
      {
        name: "PERSONAL (USDT)",
        key: "personalUsdtPurchase",
        render: (item: any) => {
          const value = +item.personalUsdtPurchase;
          return value ? NumberUtils.toFormatNumber(+item.personalUsdtPurchase, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
        },
      },
      {
        name: "TEAM (USDT)",
        key: "teamUsdtPurchase",
        render: (item: any) => {
          const value = +item.teamUsdtPurchase;
          return value ? NumberUtils.toFormatNumber(+item.teamUsdtPurchase, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
        },
      },
      {
        name: "F1",
        key: "f1Count",
      },
      {
        name: "F2",
        key: "f2Count",
      },
      {
        name: "F3",
        key: "f3Count",
      },
      {
        name: "F4",
        key: "f4Count",
      },
      {
        name: "F5",
        key: "f5Count",
      },
      {
        name: "F6",
        key: "f6Count",
      },
      {
        name: "F7",
        key: "f7Count",
      },
    ];

    return (
      <div className="UserList">
        <Table
          hasOrderColumn
          filters={[
            {
              name: "Email",
              key: "email",
              input: TableFilterInputText,
            },
          ]}
          structure={structure}
          fetchData={async (params) => {
            return AdminService.getListUserDetails({
              page: params.pageNumber,
              numberOfItemsPerPage: params.limit,
              ...params,
            });
          }}
        />

        {sponsorUserId != null && (
          <PopupWraper center title="Referral sponsor" onClose={() => setSponsorUserId(null)}>
            <Table
              structure={[
                {
                  name: "Email",
                  key: "email",
                },
                {
                  name: "Display Name",
                  key: "firstName",
                },
                {
                  name: "Tier",
                  key: "level",
                },
              ]}
              fetchData={async (params) => {
                return AdminService.getReferralSponsor(sponsorUserId, 0, {
                  page: params.pageNumber,
                  pageSize: params.limit,
                  ...params,
                });
              }}
            />
          </PopupWraper>
        )}
      </div>
    );
  })
);

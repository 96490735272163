import React, { useState, useEffect, FC, memo } from "react";
import XLSX from "xlsx";

import { moduleConfig } from "../../../modules/module.config";
import { withReportWraper } from "../wraper";
import { AdminService } from "../../../services/admin";
import { InputDateTimeRange } from "../../../components/table-filter-inputs";
import { Message, Table, Button, ObjectUtils, CreateAlert, ITableStructureItem, NumberUtils } from "../../../modules";
import { getLocaleKey } from "../../../languages";
import { Icon, InputSelect } from "../../../components";
import moment from "moment";
import { getEnv } from "../../../configs";
import { useSelector } from "../../../store";

export const ReportSystem = withReportWraper(
  memo(
    () => {
      const [rangeTime, setRangeTime] = useState({
        startTime: moment().startOf("month").format("YYYY/MM/DD HH:mm:ss"), //Date.now() - 1000 * 60 * 60 * 24 * 30,
        endTime: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"), //Date.now(),
      } as any);

      const [reports, setReports] = useState(null as any);
      const [systemReportDetail, setSystemReportDetail] = useState(null as any);
      const [modeOption, setModeOption] = useState("");

      const user = useSelector((state) => state.user);

      useEffect(() => {
        if (!rangeTime) {
          setReports(null);
        } else {
          setReports(null);

          if (user.userRole === "AGENT")
            AdminService.getSytemReportAgent({
              fromDate: new Date(rangeTime.startTime),
              toDate: new Date(rangeTime.endTime),
            })
              .then((res) => {
                const sumReports = Object.keys(res[0]).reduce((ouput: any, key: string) => {
                  //if (typeof res[0][key] === 'number') ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                  ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                  return ouput;
                }, {});

                setReports({
                  count: res.length,
                  data: res.sort((a: any, b: any) => b.start - a.start),
                  sumReports,
                });
              })
              .catch((err) => setReports({ error: err.error }));
          else
            AdminService.getSytemReport({
              fromDate: new Date(rangeTime.startTime),
              toDate: new Date(rangeTime.endTime),
            })
              .then((res) => {
                const sumReports = Object.keys(res[0]).reduce((ouput: any, key: string) => {
                  //if (typeof res[0][key] === 'number') ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                  ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                  return ouput;
                }, {});

                setReports({
                  count: res.length,
                  data: res.sort((a: any, b: any) => b.start - a.start),
                  sumReports,
                });
              })
              .catch((err) => setReports({ error: err.error }));
        }
      }, [rangeTime, user.userRole]);

      const ModeOptions = [
        { label: "Deposit", value: "deposit" },
        { label: "Withdraw", value: "withdraw" },
      ];

      const sumMiddleware = (func: (item: any) => any) => (item: any, fetchData: any, column: ITableStructureItem) => {
        if (item.isSum) {
          if (column.key === "date") return "SUM";
          if (column.key && reports && reports.sumReports && typeof reports.sumReports[column.key] === "number") {
            if (Number.isNaN(reports.sumReports[column.key])) return "--";
            const value = NumberUtils.toFormatNumber(+reports.sumReports[column.key] || 0, +getEnv("NUMBER_DECIMAL_DISPLAY"));
            return `${value}`;
          }

          return "--";
        }

        return func(item);
      };

      let structure: any = [
        {
          name: "Date",
          key: "date",
          render: sumMiddleware((item) => {
            return <div>{moment(item?.start).format("DD/MM/y")}</div>;
          }),
          sort: {
            key: "date",
          },
        },
        {
          name: "Deposit (USDT)",
          key: "depositUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.depositUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "depositUsdt",
          },
        },
        {
          name: "Withdraw (USDT)",
          key: "withdrawUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.withdrawUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "withdrawUsdt",
          },
        },
        {
          name: "Private Sale (USDT)",
          key: "tokenSellAmount",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.tokenSellAmount, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "tokenSellAmount",
          },
        },
        {
          name: "Block 1 (USDT)",
          key: "block1TokenSellAmount",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.block1TokenSellAmount, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "block1TokenSellAmount",
          },
        },
        {
          name: "Block 2 (USDT)",
          key: "block2TokenSellAmount",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.block2TokenSellAmount, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "block2TokenSellAmount",
          },
        },
        {
          name: "Block 3 (USDT)",
          key: "block3TokenSellAmount",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.block3TokenSellAmount, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "block3TokenSellAmount",
          },
        },
        {
          name: "Commission (USDT)",
          key: "rankCommission",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.rankCommission, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "rankCommission",
          },
        },
        {
          name: "Transfer (USDT)",
          key: "transferUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.transferUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "transferUsdt",
          },
        },
        {
          name: "Private Sale MKT (USDT)",
          key: "mktTokenSellAmount",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.mktTokenSellAmount, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "mktTokenSellAmount",
          },
        },
        {
          name: "Private Sale MKT",
          key: "mktTokenSellTotal",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.mktTokenSellTotal, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "mktTokenSellTotal",
          },
        },
        {
          name: "Private Sale",
          key: "tokenSellTotal",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.tokenSellTotal, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "tokenSellTotal",
          },
        },
        {
          name: "Block 1",
          key: "block1TokenSellTotal",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.block1TokenSellTotal, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "block1TokenSellTotal",
          },
        },
        {
          name: "Block 2",
          key: "block2TokenSellTotal",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.block2TokenSellTotal, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "block2TokenSellTotal",
          },
        },
        {
          name: "Block 3",
          key: "block3TokenSellTotal",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.block3TokenSellTotal, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "block3TokenSellTotal",
          },
        },
      ];

      structure = structure.filter((v: any) => {
        if (modeOption === "deposit") return ["date", "depositUsdt"].includes(v.key);
        if (modeOption === "withdraw") return ["date", "withdrawUsdt"].includes(v.key);
        return true;
      });

      const handleExportExcel = async () => {
        return new Promise((resolve) => {
          try {
            const reportData = ObjectUtils.getIn(reports, "data", []);
            const fileHead = structure.map((v: any) => v.name);
            const data = reportData.map((item: any) =>
              structure.map((column: any, index: number) => {
                if (index === 0) return moment(item?.start).format("DD/MM/y");
                if (
                  [
                    "depositUsdt",
                    "withdrawUsdt",
                    "tokenSellTotal",
                    "block1TokenSellTotal",
                    "block2TokenSellTotal",
                    "block3TokenSellTotal",
                    "rankCommission",
                    "transferUsdt",
                    "mktTokenSellAmount",
                    "mktTokenSellTotal",
                    "tokenSellAmount",
                    "block1TokenSellAmount",
                    "block2TokenSellAmount",
                    "block3TokenSellAmount"
                  ].includes(column?.key)
                )
                  return item[column.key] ? NumberUtils.toFormatNumber(+item[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`;
                return item[column.key] || 0;
              })
            );

            const dataExport = [
              fileHead,
              ...data,
              structure.map((column: any, index: number) => {
                if (index === 0) return "SUM";
                if (column.key === "date") return "SUM";
                if (
                  [
                    "depositUsdt",
                    "withdrawUsdt",
                    "tokenSellTotal",
                    "block1TokenSellTotal",
                    "block2TokenSellTotal",
                    "block3TokenSellTotal",
                    "rankCommission",
                    "transferUsdt",
                    "mktTokenSellAmount",
                    "mktTokenSellTotal",
                    "tokenSellAmount",
                    "block1TokenSellAmount",
                    "block2TokenSellAmount",
                    "block3TokenSellAmount"
                  ].includes(column?.key)
                )
                  return reports.sumReports[column.key]
                    ? NumberUtils.toFormatNumber(+reports.sumReports[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY"))
                    : `0.00000000`;
                return reports.sumReports[column.key] || "0";
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(dataExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(wb, `System report ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
              type: "binary",
            });

            resolve(
              CreateAlert({
                type: "success",
                message: "Export data success.",
              })
            );
          } catch (error) {
            resolve(
              CreateAlert({
                type: "danger",
                // @ts-ignore
                message: error.message,
              })
            );
          }
        });
      };

      return (
        <section className="box">
          <div className="title">System report</div>

          <SystemReportDetailPopup detail={systemReportDetail} onClose={() => setSystemReportDetail(null)} />

          <div className="SystemReport">
            <div className="SystemReport__Head">
              <div className="item">
                <div className="label">Range time</div>
                <InputDateTimeRange
                  onChange={(e) => setRangeTime(e)}
                  startTimeDefaultValue={rangeTime?.startTime}
                  endTimeDefaultValue={rangeTime?.endTime}
                  format={"dd/MM/y"}
                />
              </div>
              <div className="item">
                <div className="label">Mode</div>
                <InputSelect options={ModeOptions} onChange={(e) => setModeOption(e)} onTouched={() => false} value={modeOption} name="" />
              </div>

              <div className="item">
                <div className="label">Export</div>
                <Button
                  label="Excel"
                  buttonType="success"
                  disabled={!reports || (reports.data && reports.data.length === 0)}
                  onClick={handleExportExcel}
                />
              </div>
            </div>

            {(() => {
              if (reports && !reports.data) return <Message type="emptyData" message={moduleConfig.translate("no-data")} />;
              if (!rangeTime) return <Message type="info" message="You have to select range time to export report" />;
              if (!reports) return <Message type="loading" />;
              if (reports.error) return <Message type="error" {...reports.error} />;

              return (
                <Table
                  doubleScroll
                  hasOrderColumn
                  className="overload hideFooter"
                  structure={structure}
                  fetchData={async (params) => {
                    let data = [...reports.data];

                    if (params["date"] === "increase") data = data.sort((a: any, b: any) => a.start - b.start);
                    if (params["date"] === "descrease") data = data.sort((a: any, b: any) => b.start - a.start);

                    if (params["depositUsdt"] === "increase") data = data.sort((a: any, b: any) => a.depositUsdt - b.depositUsdt);
                    if (params["depositUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.depositUsdt - a.depositUsdt);

                    if (params["withdrawUsdt"] === "increase") data = data.sort((a: any, b: any) => a.withdrawUsdt - b.withdrawUsdt);
                    if (params["withdrawUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.withdrawUsdt - a.withdrawUsdt);

                    if (params["tokenSellTotal"] === "increase") data = data.sort((a: any, b: any) => a.tokenSellTotal - b.tokenSellTotal);
                    if (params["tokenSellTotal"] === "descrease") data = data.sort((a: any, b: any) => b.tokenSellTotal - a.tokenSellTotal);

                    if (params["block1TokenSellTotal"] === "increase") data = data.sort((a: any, b: any) => a.block1TokenSellTotal - b.block1TokenSellTotal);
                    if (params["block1TokenSellTotal"] === "descrease") data = data.sort((a: any, b: any) => b.block1TokenSellTotal - a.block1TokenSellTotal);

                    if (params["block2TokenSellTotal"] === "increase") data = data.sort((a: any, b: any) => a.block2TokenSellTotal - b.block2TokenSellTotal);
                    if (params["block2TokenSellTotal"] === "descrease") data = data.sort((a: any, b: any) => b.block2TokenSellTotal - a.block2TokenSellTotal);
                    
                    if (params["block3TokenSellTotal"] === "increase") data = data.sort((a: any, b: any) => a.block3TokenSellTotal - b.block3TokenSellTotal);
                    if (params["block3TokenSellTotal"] === "descrease") data = data.sort((a: any, b: any) => b.block3TokenSellTotal - a.block3TokenSellTotal);

                    if (params["rankCommission"] === "increase") data = data.sort((a: any, b: any) => a.rankCommission - b.rankCommission);
                    if (params["rankCommission"] === "descrease") data = data.sort((a: any, b: any) => b.rankCommission - a.rankCommission);

                    if (params["transferUsdt"] === "increase") data = data.sort((a: any, b: any) => a.transferUsdt - b.transferUsdt);
                    if (params["transferUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.transferUsdt - a.transferUsdt);

                    if (params["mktTokenSellAmount"] === "increase") data = data.sort((a: any, b: any) => a.mktTokenSellAmount - b.mktTokenSellAmount);
                    if (params["mktTokenSellAmount"] === "descrease") data = data.sort((a: any, b: any) => b.mktTokenSellAmount - a.mktTokenSellAmount);

                    if (params["mktTokenSellTotal"] === "increase") data = data.sort((a: any, b: any) => a.mktTokenSellTotal - b.mktTokenSellTotal);
                    if (params["mktTokenSellTotal"] === "descrease") data = data.sort((a: any, b: any) => b.mktTokenSellTotal - a.mktTokenSellTotal);

                    if (params["tokenSellAmount"] === "increase") data = data.sort((a: any, b: any) => a.tokenSellAmount - b.tokenSellAmount);
                    if (params["tokenSellAmount"] === "descrease") data = data.sort((a: any, b: any) => b.tokenSellAmount - a.tokenSellAmount);

                    if (params["block1TokenSellAmount"] === "increase") data = data.sort((a: any, b: any) => a.block1TokenSellAmount - b.block1TokenSellAmount);
                    if (params["block1TokenSellAmount"] === "descrease") data = data.sort((a: any, b: any) => b.block1TokenSellAmount - a.block1TokenSellAmount);

                    if (params["block2TokenSellAmount"] === "increase") data = data.sort((a: any, b: any) => a.block2TokenSellAmount - b.block2TokenSellAmount);
                    if (params["block2TokenSellAmount"] === "descrease") data = data.sort((a: any, b: any) => b.block2TokenSellAmount - a.block2TokenSellAmount);

                    if (params["block3TokenSellAmount"] === "increase") data = data.sort((a: any, b: any) => a.block3TokenSellAmount - b.block3TokenSellAmount);
                    if (params["block3TokenSellAmount"] === "descrease") data = data.sort((a: any, b: any) => b.block3TokenSellAmount - a.block3TokenSellAmount);

                    return {
                      data: [{ isSum: true }, ...data],
                      count: data.length,
                    };
                  }}
                />
              );
            })()}
          </div>
        </section>
      );
    },
    () => true
  )
);

export const SystemReportDetailPopup: FC<{
  detail: any;
  onClose: () => void;
}> = (props) => {
  if (!props.detail) return null;

  const item = props.detail;

  const data = [
    {
      label: "Start",
      value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "End",
      value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Modified",
      value: `${new Date(item.modified).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Total won/lose volume",
      value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}`,
    },
    {
      label: "Hot Wallet",
      value: (
        <span>
          GPO:{" "}
          {(+item.hotWalletGp2).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}{" "}
          <br />
          USDT:{" "}
          {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    // {
    //     label: 'Deposit', value: <span>
    //         ETH: {(+item.depositEth).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} <br />
    //         USDT: {(+item.depositUsdt).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
    //     </span>
    // },
    // {
    //   label: "Withdraw",
    //   value: (
    //     <span>
    //       GPO:{" "}
    //       {(+item.withdrawGp2).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       USDT:{" "}
    //       {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}
    //     </span>
    //   ),
    // },
    // {
    //   label: "User 's Balance",
    //   value: (
    //     <span>
    //       MEGADAO:{" "}
    //       {(+item.userBalanceGp2).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       {/* USD: {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} <br /> */}
    //       USDT:{" "}
    //       {(+item.userBalanceUsdt).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       {/* BUT: {(+item.userBalanceBut).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} */}
    //     </span>
    //   ),
    // },
    // {
    //   label: "Safe",
    //   value: (
    //     <span>
    //       GPO:{" "}
    //       {(+item.safeGp2).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       USDT:{" "}
    //       {(+item.safeUsdt).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}
    //     </span>
    //   ),
    // },
    {
      label: "Login Count",
      key: "loginCount",
      value: item.loginCount,
    },
    {
      label: "Direct Commission",
      key: "directCommission",
      value: item.directCommission,
    },
    {
      label: "CashBack",
      key: "cashBack",
      value: (+item.cashBack).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    },
    {
      label: "New Package",
      key: "newPackage",
      value: item.newPackage,
    },
    {
      label: "Trading Commission",
      key: "rankCommission",
      value: item.rankCommission,
    },
  ];

  return (
    <div
      className="SystemReport__DetailPopup"
      id="SystemReport__DetailPopup"
      onClick={(e: any) => (e.target.id === "SystemReport__DetailPopup" ? props.onClose() : null)}
    >
      <div className="box">
        <div className="boxTitle">
          <span>Detail Report</span>
          <div className="btnClose" onClick={() => props.onClose()}>
            <Icon.Close />
          </div>
        </div>
        <div className="content">
          <table>
            <tbody>
              {data.map((row, key) => (
                <tr key={key}>
                  <td>{row.label}</td>
                  <td>{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";

export interface ITransactionWraperProps extends RouteComponentProps {}

export const withTransactionWraper = (Component: FC<ITransactionWraperProps>) => (props: ITransactionWraperProps) => {
  return (
    <div className="withTransactionWraper">
      <div className="TabLinks">
        <NavLink
          to={Routes.transactionList.path}
          exact={true}
          // isActive={() => {
          //   return props?.location?.pathname?.includes("/transaction-list");
          // }}
        >
          <Icon.Exchange />
          Transactions
        </NavLink>

        <NavLink 
            to={Routes.withdraw.path} 
            exact={true}
            isActive={() => {
              return props?.location?.pathname?.includes("/transactions-withdraws");
            }}
          >
            <Icon.KYC />
            Withdraw Progress
          </NavLink>

        {/* <NavLink to={Routes.AirdropPage.path} exact={true}>
            <Icon.AirdropIcon />
            Airdrop
          </NavLink> */}
      </div>

      <Component {...props} />
    </div>
  );
};

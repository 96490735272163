import React, { FC, useState } from "react";
import "./index.scss";
import { Button } from "../../../../modules";

export const CompletePrivate: FC<{ type: any; onFinishEdit: () => void }> = ({ type, onFinishEdit }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="CompletePrivate">
      <p>{`Are you sure you want to ${type} the private sale?`}</p>

      <Button
        label={type}
        onClick={() => {
          setLoading(true);
          onFinishEdit();
        }}
        isMiddle
        isLoading={loading}
        disabled={loading}
        buttonType={type === "approve" ? "success" : "danger"}
        className="btnBan"
        type="submit"
      />
    </div>
  );
};

import moment from "moment";
import React, { memo, useState } from "react";
import XLSX from "xlsx";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { getEnv } from "../../../configs";
import { translate } from "../../../languages";
import { Button, CreateAlert, ITableStructureItem, NumberUtils, ObjectUtils, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withUserWraper } from "../wraper";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { PopupWraper } from "../../../components/popup";
import { CompletePrivate } from "./complete-private";

export const UserBuyPrivateSale = memo(() => {
    const [params, setParams] = useState([] as any[]);
    const [showPopup, setShowPopup] = useState<any>(null);
    const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

    const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
    const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

    const structure: ITableStructureItem[] = [
      {
        name: "EMAIL",
        key: "email",
      },
      {
        name: "ADDRESS",
        key: "address",
      },
      {
        name: "AMOUNT",
        key: "amount",
        render: (item: any) => NumberUtils.toFormatNumber(+item?.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")),
      },
      {
        name: "PRICE",
        key: "price",
        render: (item: any) => NumberUtils.toFormatNumber(+item?.price, +getEnv("NUMBER_DECIMAL_DISPLAY")),
      },
      {
        name: "TOKEN",
        key: "token",
        render: (item: any) => NumberUtils.toFormatNumber(+item?.token),
      },
      {
        name: "TxHash",
        key: "transactionHash",
        render: (item) => {
          const hash = ObjectUtils.getIn(item, "transactionHash");
          if (hash)
            return (
              <a href={`${getEnv("BSC_SCAN")}${hash}`} target="__blank">
                Click me
              </a>
            );

          return "--";
        },
      },
      {
        name: "CREATED",
        key: "created",
        render: (item) => moment(item?.created).format("DD/MM/y HH:mm:ss"),
      },
      {
        name: "STATUS",
        key: "status",
        render: (item) => <span className={`status status--${item?.status?.toLowerCase()}`}>{translate(item?.status)}</span>,
      },
      {
        name: "ACTIONS",
        render: (item: any, fetchData: any) => {
          return (
            <div className="d-flex flex-column">
              <Button
                type="button"
                buttonType="success-outline"
                label="Approve"
                onClick={() =>
                  setShowPopup({
                    type: "approve",
                    id: item?.tokenSellHistoryId,
                  })
                }
                disabled={item?.status !== "PENDING"}
              />

              <Button
                className="mt10 mb10"
                type="button"
                buttonType="danger-outline"
                label="Reject"
                onClick={() =>
                  setShowPopup({
                    type: "rejected",
                    id: item?.tokenSellHistoryId,
                  })
                }
                disabled={item?.status !== "PENDING"}
              />
            </div>
          );
        },
      },
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AdminService.getListPromotion({
            ...params,
            page: 1,
            numberOfItemsPerPage: 10000,
          });

          const data = response.data;

          const fileHead = structure.map((v) => v.name);
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                if (!column.key) return "";
                return item[column.key];
              })
            ),
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

          const now = new Date();
          XLSX.writeFile(
            wb,
            `User Buy Private Sale ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`,
            {
              type: "binary",
            }
          );

          resolve(
            CreateAlert({
              type: "success",
              message: "Export data success.",
            })
          );
        } catch (error) {
          // @ts-ignore
          resolve(CreateAlert({ type: "danger", message: error.message }));
        }
      });
    };

    return (
      <>
        <div className="UserPrivateSale">
          <div className="mb20 d-flex align-items-center justify-content-between">
            <Button label="Export to Excel" buttonType="success" onClick={handleExportExcel} />
          </div>

          <Table
            hasOrderColumn
            hasSearchButton={true}
            forceUpdateTable={forceUpdateTable}
            filters={[
              {
                name: "Email",
                key: "searchString",
                input: TableFilterInputText,
              },
              {
                name: translate("type"),
                key: "status",
                input: (e) => (
                  <TableFilterInputSelect {...e} options={AdminService.getListUserStatusPrivate()} isClearable={true} isSearchable={false} />
                ),
              },
              {
                name: translate("time"),
                key: "created",
                input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
                defaultValue: {
                  fromDate: startOfMonth, //new Date(last1MothDate),
                  toDate: endOfDay, //new Date(),
                },
              },
            ]}
            structure={structure}
            fetchData={async (state) => {
              let params = { ...state };

              if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
              if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

              setParams(params);

              return AdminService.getListUserPrivateSale({
                ...params,
                page: params.pageNumber,
                numberOfItemsPerPage: params.limit,
                status: params.status ? [params.status] : "",
              }).then((res) => {
                return res;
              });
            }}
          />
        </div>

        {showPopup && (
          <PopupWraper center title={`${showPopup?.type.toLocaleLowerCase()} PRIVATE SALE`} onClose={() => setShowPopup(null)}>
            <CompletePrivate
              type={showPopup?.type}
              onFinishEdit={() => {
                let arrPush = [];
                arrPush = [
                  showPopup?.type === "approve" ? AdminService.approvePrivateSale(showPopup?.id) : AdminService.rejectPrivateSale(showPopup?.id),
                ];

                Promise.all(arrPush)
                  .then(() => {
                    CreateAlert({
                      type: "success",
                      message: "Successfully",
                    });
                  })
                  .catch((error) => {
                    CreateAlert({
                      message: translate(error.message),
                      type: "danger",
                    });
                  })
                  .finally(() => {
                    setForceUpdateTable(Math.random());
                    setShowPopup(null);
                  });
              }}
            />
          </PopupWraper>
        )}
      </>
    );
});

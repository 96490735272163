import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import { Button, Icon, ITableStructureItem, Table } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { SET_DETAIL_NEWS } from "../../../services/config/editNews.reduce";
import { store } from "../../../store";

const NewsCompnt = () => {
  const { push } = useHistory();

  const structure: ITableStructureItem[] = [
    {
      name: "Title",
      key: "title",
      className: "title",
      render: (item) => {
        return <span>{JSON.parse(item?.title)["en-US"]}</span>;
      },
    },
    {
      name: "Slug",
      key: "slug",
      className: "title",
    },
    {
      name: "Content",
      key: "content",
      className: "content",
      render: (item) => <span dangerouslySetInnerHTML={{ __html: JSON.parse(item?.content)["en-US"] }}></span>,
    },
    // {
    //   name: "Image",
    //   key: "image",
    //   render: (item) => (
    //     <a href={item?.image} target={"_blank"}>
    //       <img className="image" src={item?.image} alt="" />
    //     </a>
    //   ),
    // },
    // {
    //   name: "Created Date",
    //   key: "created",
    //   render: (item) => moment(item?.created).format("DD/MM/y HH:mm:ss"),
    // },
    // {
    //   name: "Expiration Date",
    //   key: "expirationDate",
    //   render: (item) => moment(item?.expirationDate).format("DD/MM/y HH:mm:ss"),
    // },
    // {
    //   name: "Status",
    //   key: "status",
    //   render: (item) => <span className={`status status--${item?.status?.toLowerCase()}`}>{item?.status?.toUpperCase()}</span>,
    // },
    {
      name: "Actions",
      render: (item) => {
        return (
          <div className="action">
            <div
              // className={`action__edit action__edit--${item?.status?.toLowerCase()}`}
              onClick={() => {
                // if (item?.status?.toLowerCase() === "expired") return;
                store.dispatch({
                  type: SET_DETAIL_NEWS,
                  data: {
                    ...item,
                    title: JSON.parse(item?.title),
                    content: JSON.parse(item?.content),
                  },
                });
                push(Routes.EditNews.path);
              }}
            >
              <Icon.ActionEditIcon />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Button label="Create News" onClick={() => push(Routes.CreateNews.path)} buttonType="success" icon={Icon.AddNewIcon} className="btnBan mb20" />
      <Table
        hasOrderColumn
        itemPerPages={10}
        structure={structure}
        fetchData={async (params) => {
          if (params["fromDate"]) params["fromDate"] = moment(params["fromDate"]).toISOString();
          if (params["toDate"]) params["toDate"] = moment(params["toDate"]).toISOString();
          return ConfigService.getListNews();
        }}
      />
    </>
  );
};

export default NewsCompnt;
